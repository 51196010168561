import React, { useState } from "react";
import { logoMc } from "../assets/images";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../redux/api/auth/authSlice";
import { getFirstTwoLetters } from "../hooks";

const TopNav = ({ setToogleSideMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogOut());
  };
  // console.log(user); 
  return (
    <div className="w-full h-20 flex items-center bg-white border-b">
      {/* logo */}
      <div className="border-r shadow-md basis-[20%] hidden  h-20 overflow-hidden shrink-0 lg:flex justify-center items-center">
        <img src={logoMc} alt="" className="object-cover h-full py-2" />
      </div>
      {/* menu icon */}
      <AiOutlineMenuUnfold
        className="text-3xl cursor-pointer ml-5 lg:hidden"
        onClick={() => setToogleSideMenu((prev) => !prev)}
      />
      {/* profile */}
      <div className="relative flex-grow flex justify-end items-center p-5 basis-full">
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className="w-12 h-12 active:scale-95 duration-200 cursor-pointer rounded-full uppercase bg-primary text-white flex justify-center items-center text-xl font-bold"
        >
           {getFirstTwoLetters(user?.nom)}
        </button>
        <div
          className={`absolute top-full right-5 bg-white shadow p-3 rounded ${
            isOpen ? "block z-50" : "hidden"
          }`}
        >
          <button onClick={handleLogout}>
            <p className="text-lg">Déconnexion</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
