import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { QuestionDuotone } from "../assets/icons";
import { sideLinks } from "../utils";

const SideMenu = ({ setToogleSideMenu, toogleSideMenu }) => {
  const { pathname } = useLocation();
  return (
    <nav
      transition-style="in:circle:bottom-left"
      className={`border-r  bg-white shadow-md ${
        toogleSideMenu ? "absolute left-0 w-56  z-50" : "hidden lg:block"
      }  h-screen duration-500 transition-all ease-in-out overflow-hidden shrink-0 tracking-wider`}
    >
      {/* links */}
      <div className="w-full flex flex-col h-[calc(100vh-80px)] font-medium justify-between overflow-y-auto pb-5 gap-5">
        <div className="">
          {sideLinks.map((link) => (
            <NavLink
              key={link.id}
              to={link.path}
              className={({ isActive }) =>
                `${
                  isActive || (pathname === "/review" && link.id === 1)
                    ? "text-white border-primary bg-primary "
                    : "text-black"
                } py-4 flex items-center gap-5 relative`
              }
              onClick={() => setToogleSideMenu((prev) => (prev = false))}
            >
              <span
                className={`absolute w-1 rounded-lg  bg-white/50 duration-300 transition-all shrink-0 ${
                  pathname === link.path ||
                  (pathname === "/replay" && link.id === 1)
                    ? "h-full opacity-100"
                    : "h-0 opacity-0"
                } `}
              />
              <div className="flex items-center gap-3 shrink-0 pl-4">
                <link.icon className="text-xl" />
                {link.title}
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;
