import React, { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import { WebSocketProvider } from "./socket-io/WebSocketService";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import CustomLoader from "./components/loader/CustomLoader";

import LiveShoppingLayout from "./layout/LiveShoppingLayout";
import RootLayout from "./layout/RootLayout";
import { setUserInfo } from "./redux/api/auth/authSlice";
const LiveApercu = lazy(() => import("./pages/LiveApercu"));
const ProduitEnLivePopup = lazy(() => import("./pages/ProduitEnLivePopup"));
const ReviewLives = lazy(() => import("./pages/Review"));
const Products = lazy(() => import("./pages/Products"));
const Analytic = lazy(() => import("./pages/Analytic"));
const Home = lazy(() => import("./pages/Home"));
const Users = lazy(() => import("./pages/Users"));
const NewLives = lazy(() => import("./pages/newLives"));
const Live = lazy(() => import("./pages/Live"));
const WpLink = lazy(() => import("./pages/WpLink"));
const Login = lazy(() => import("././pages/Login"));

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      const decodedToken = jwtDecode(isLoggedIn);
      if (decodedToken) {
        dispatch(setUserInfo(isLoggedIn));
      }
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        navigate("/login", { replace: true });
        localStorage.removeItem("credentials");
      }
    } else navigate("/login", { replace: true });
  }, [navigate, isLoggedIn]);

  return (
    <Suspense fallback={<CustomLoader />}>
      <div className="w-full h-full">
        <WebSocketProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              element={
                isLoggedIn ? <RootLayout /> : <Navigate to="/login" replace />
              }
            >
              <Route element={<LiveShoppingLayout />}>
                <Route index path="/" element={<Home />} />
                <Route path="/review" element={<ReviewLives />} />
              </Route>
              <Route path="live" element={<Live />} />
              <Route path="live/analytic" element={<LiveApercu />} />
              <Route path="analytic" element={<Analytic />} />
              <Route path="product" element={<Products />} />
              <Route path="users" element={<Users />} />
              <Route path="wp-live" element={<WpLink />} />
              <Route path="/new-live" element={<NewLives />} />
            </Route>
            <Route path="/product/:id" element={<ProduitEnLivePopup />} />
          </Routes>
        </WebSocketProvider>

        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          draggable
          // pauseOnHover
          limit={5}

          // theme="light"
        />
        <Toaster reverseOrder={false} />
      </div>
    </Suspense>
  );
};

export default App;
