import { configureStore } from "@reduxjs/toolkit";
import { liveApi } from "./api/liveApi";
import { apiSlice } from "./api/apiSlice";
import { authApi } from "./api/auth/authApi";
import chatReducer from "./api/chats/chatSlice";
import liveSlice from "./api/live/liveSlice";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authSlice from "./api/auth/authSlice";

export const store = configureStore({
  reducer: {
    [liveApi.reducerPath]: liveApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    chats: chatReducer,
    lives: liveSlice,
    auth: authSlice,
  },
  // devTools:true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      liveApi.middleware,
      apiSlice.middleware,
      authApi.middleware
    ),
});

setupListeners(store.dispatch);
