import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  access_token: JSON.parse(localStorage.getItem("credentials"))
    ? JSON.parse(localStorage.getItem("credentials"))?.access_token
    : "",
  refresh_token: JSON.parse(localStorage.getItem("credentials"))
    ? JSON.parse(localStorage.getItem("credentials"))?.refresh_token
    : "",
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    userLogOut: (state, action) => {
      state.access_token = "";
      state.refresh_token = "";
      localStorage.removeItem("credentials");
    },
    decodeToken(state, action) {
      const decodedToken = jwtDecode(action.payload);
      if (decodedToken) {
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          state.access_token = "";
          state.refresh_token = "";
          state.user = null;
          localStorage.removeItem("credentials");
        } else {
          state.user = decodedToken;
        }
      } else {
        state.access_token = "";
        state.refresh_token = "";
        state.user = null;
        localStorage.removeItem("credentials");
      }
    },
    setUserInfo(state, action) {
      const decodedToken = jwtDecode(action.payload);
      state.user = decodedToken;
    },
  },
});

export const { userLogOut, userLogin, decodeToken, setUserInfo } =
  authSlice.actions;

export default authSlice.reducer;
