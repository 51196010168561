import React from "react";
import { AddIcon } from "../../assets/icons";

const CustomAddButton = ({ handleClick, title, className, hoverbg }) => {
  return (
    <button
      onClick={handleClick}
      className={
        "relative overflow-hidden group rounded-md active:scale-95 duration-300 py-2 px-3 lg:px-5  hover:bg-transparent  border-white border-transparent shrink-0 border font-medium flex items-center gap-1 " +
        className
      }
    >
      <AddIcon className="text-2xl z-10 duration-200 group-hover:text-blac" />
      <span className="text-lg z-10 duration-200 group-hover:text-">
        {title}
      </span>
      <span
        className={
          "absolute w-full h-full -left-36 top-0 -rotate-45 group-hover:rotate-0 group-hover:left-0 duration-500 " +
          (hoverbg ? hoverbg : "bg-white")
        }
      />
      <span
        className={
          "absolute w-full h-full  -right-36 top-0 -rotate-45 group-hover:rotate-0 group-hover:right-0 duration-500 " +
          (hoverbg ? hoverbg : "bg-white")
        }
      />
    </button>
  );
};

export default CustomAddButton;
