import React from "react";

const Button = ({ state, loading, title, onClick, className, type }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || state}
      type={type || "submit"}
      className={`group relative overflow-hidden bg-black rounded-md disabled:cursor-not-allowed disabled:opacity-50 text-white py-2 px-5 active:scale-95 duration-300 ${className} `}
    >
      {loading? "Création...":title}
      <span className="bg-primary shadow-primary absolute -top-[150%] left-0 inline-flex w-80 h-[5px] rounded-md opacity-50 group-hover:top-[150%] duration-500 shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]"></span>
    </button>
  );
};

export default Button;
