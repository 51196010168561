import { PieChart, StoreIcon, UsersIcon, Videocam } from "../assets/icons";

export const sideLinks = [
  {
    id: 1,
    title: "Achats en direct",
    path: "/",
    icon: Videocam,
  },
  {
    id: 3,
    title: "Analytique",
    path: "/analytic",
    icon: PieChart,
  },
  {
    id: 4,
    title: "Produits",
    path: "/product",
    icon: PieChart,
  },
  {
    id: 5,
    title: "Utilisateurs",
    path: "/users",
    icon: UsersIcon,
  },
  {
    id: 6,
    title: "Wp Linke",
    path: "/wp-live",
    icon: StoreIcon,
  },
];

export function durationToSeconds(duration) {
  // Extraire les heures, minutes et secondes de la durée
  const hours = duration?.includes("H")
    ? parseInt(duration?.split("H")[0].slice(2))
    : 0;
  const minutes = duration?.includes("M")
    ? parseInt(duration?.split("M")[0].split("T").pop())
    : 0;
  const seconds = duration?.includes("S")
    ? parseFloat(duration?.split("S")[0].split("T").pop())
    : 0;

  // Calculer la durée totale en secondes
  return hours * 3600 + minutes * 60 + seconds;
}
export function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += hours + "h ";
  }

  if (minutes > 0 || hours > 0) {
    formattedDuration += minutes + "min ";
  }

  formattedDuration += Math.round(remainingSeconds) + "s";

  return formattedDuration.trim();
}
export function convertirEnSecondes(temps) {
  const partiesTemps = temps.split(":");
  const heures = parseInt(partiesTemps[0], 10) || 0;
  const minutes = parseInt(partiesTemps[1], 10) || 0;
  const secondes = parseInt(partiesTemps[2], 10) || 0;

  return heures * 3600 + minutes * 60 + secondes;
}
export function validateTimeFormat(inputValue) {
  // Expression régulière pour le format hh:mm:ss
  const regex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

  // Vérifier si la valeur correspond au format
  return regex.test(inputValue);
}
