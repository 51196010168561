import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const liveApi = createApi({
  reducerPath: "liveApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Lives", "Chat", "Produit", "Analytic"],
  endpoints: (builder) => ({
    // les lives en cour
    getCurrentLives: builder.query({
      query: () => `liveStream/current`,
      providesTags: ["Lives"],
    }),
    // TOUS les lives EN COURS ET PASSE
    getAllLives: builder.query({
      query: () => `liveStream/all`,
      providesTags: ["Lives"],
    }),
    getLiveById: builder.query({
      query: (liveID) => `liveStream/${liveID}`,
      providesTags: ["Lives"],
    }),
    // CREATION DE LIVE
    createLive: builder.mutation({
      query: (data) => {
        return {
          url: "liveStream",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Lives"],
    }),
    // CREATION AVEC IMG DE LIVE
    liveCreation: builder.mutation({
      query: (data) => {
        return {
          url: "liveStream/save",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Lives"],
    }),
    // MODIFICATION DU LIVE
    updateLive: builder.mutation({
      query: ({ id, data }) => ({
        url: `liveStream/updateLive/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Lives"],
    }),
    // SUPPRESSION DU LIVE
    deleteLive: builder.mutation({
      query: ({ id }) => ({
        url: `liveStream/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lives"],
    }),
    // TERMINER LE LIVE
    endLive: builder.mutation({
      query: (id) => ({
        url: `liveStream/stop/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Lives"],
    }),
    // RECUPERATION DES LIVEs PASSER les REPLAY
    getReplayLives: builder.query({
      query: () => `liveStream/replay`,
      providesTags: ["Lives"],
    }),
    // RECUPERATION DES MESSAGES DU LIVES
    getLiveChatsById: builder.query({
      query: (id) => `message/parent/${id}`,
      providesTags: ["Chat"],
    }),
    getLiveProductsById: builder.query({
      query: (id) => `LiveProduct/stream/${id}`,
      providesTags: ["Lives"],
    }),
    getAllProducts: builder.query({
      query: (page = 0, pageSize = 40) =>
        `product?page=${page}&size=${pageSize}`,
      providesTags: ["Produit"],
    }),
    // EPINGLE CHAT
    pinnedLiveChat: builder.mutation({
      query: (id) => ({
        url: `message/pined_chat/${id}`,
        method: "PATCH",
        // body: data,
      }),
      invalidatesTags: ["Chat"],
    }),
    // SUPPRESSION DE CHAT
    deleteLiveChat: builder.mutation({
      query: (id) => ({
        url: `message/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Chat", "Analytic"],
    }),
    enableLiveToWordpress: builder.mutation({
      query: (id) => ({
        url: `liveStream/start/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Lives"],
    }),
    getProductToWordpresss: builder.query({
      query: (idSteram) => `LiveProduct/getEnableProduct/${idSteram}`,
      providesTags: ["Produit"],
    }),
    getLiveProducts: builder.query({
      query: (idSteram) => `LiveProduct/stream/${idSteram}`,
      providesTags: ["Produit", "Lives"],
    }),
    getProductsBySearch: builder.query({
      query: (name) => `product/searchProductName/${name}`,
      providesTags: ["Produit"],
    }),
    addProductToWordpresss: builder.mutation({
      query: ({ idSteram, idProduit }) => ({
        url: `LiveProduct/${idSteram}/${idProduit}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Produit", "Lives"],
    }),
    addViewers: builder.mutation({
      query: (id) => ({
        url: `liveStream/incr/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Analytic"],
    }),
    removeViewers: builder.mutation({
      query: (id) => ({
        url: `liveStream/decr/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Analytic"],
    }),
    getAllLiveAnalytics: builder.query({
      query: () => `liveStream/analytics/all`,
    }),
    getOneLiveAnalytic: builder.query({
      query: (id) => `liveStream/analytics/${id}`,
      providesTags: ["Analytic"],
    }),
    addToPanier: builder.mutation({
      query: (data) => {
        return {
          url: "liveStream/addToCard",
          method: "POST",
          body: data,
        };
      },
      // invalidatesTags: ["Lives"],
    }),
    productStartTime: builder.mutation({
      query: ({ data, productId }) => {
        return {
          url: `product/presentation_start/${productId}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["Produit"],
    }),
    getProductByProductID: builder.query({
      query: (id) => `product/productPresentation/${id}`,
      providesTags: ["Produit"],
    }),
  }),
});

export const {
  useGetProductByProductIDQuery,
  useProductStartTimeMutation,
  useGetProductsBySearchQuery,
  useAddViewersMutation,
  useRemoveViewersMutation,
  useGetOneLiveAnalyticQuery,
  useGetAllLiveAnalyticsQuery,
  useGetLiveProductsQuery,
  useGetLiveByIdQuery,
  useGetProductToWordpresssQuery,
  useGetAllLivesQuery,
  useGetCurrentLivesQuery,
  useGetReplayLivesQuery,
  useCreateLiveMutation,
  useLiveCreationMutation,
  useUpdateLiveMutation,
  useEndLiveMutation,
  useDeleteLiveMutation,
  useGetLiveChatsByIdQuery,
  useDeleteLiveChatMutation,
  usePinnedLiveChatMutation,
  useGetLiveProductsByIdQuery,
  useGetAllProductsQuery,
  useAddProductToWordpresssMutation,
  useEnableLiveToWordpressMutation,
  useAddToPanierMutation,
} = liveApi;
