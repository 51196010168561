import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "../components/SideMenu";
import TopNav from "../components/TopNav";

const RootLayout = () => {
  const [toogleSideMenu, setToogleSideMenu] = useState(false);

  return (
    <div className="w-full bg-white h-screen overflow-hidden">
      <TopNav setToogleSideMenu={setToogleSideMenu} />
      <div className="w-full h-full flex shrink-0">
        <div className="relative lg:basis-[20%]">
          <SideMenu
            toogleSideMenu={toogleSideMenu}
            setToogleSideMenu={setToogleSideMenu}
          />
        </div>
        <main className="basis-full  lg:basis-[80%] shrink-0">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RootLayout;
