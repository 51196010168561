import React from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AddIcon, SearchIcon } from "../assets/icons";
import { CustomButton } from "../components/ui";
import { useDispatch } from "react-redux";
import { setSearchText } from "../redux/api/live/liveSlice";

const LiveShoppingLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subLinks = [
    { title: "Lives", path: "/" },
    { title: "Replay", path: "/review" },
  ];
  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  return (
    <div className="w-full h-full px-5 bg-primary text-white ">
      {/* title and search */}
      <div className="flex justify-between flex-wrap items-center pt-5 pb-2">
        <h1 className=" font-bold text-3xl mb-5">Live Shopping</h1>
        {/* input search */}
        <div className="flex flex-wra items-center justify-center gap-3">
          <div className="w-full h-full flex items-center border border-white gap-2 rounded-md pr-2">
            <input
              id="search"
              type="text"
              placeholder="Chercher un live"
              className="w-full rounded-md placeholder:text-white/80 py-2 pl-3 outline-none border-none bg-transparent"
              onChange={handleSearchChange}
            />
            <label htmlFor="search">
              <SearchIcon className="text-lg" />
            </label>
          </div>
          <CustomButton
            handleClick={() => navigate("/new-live")}
            title={"Create Live"}
            className={" hover:text-black"}
          />
        </div>
      </div>
      {/* sub bar */}
      <div className="border-b-2 border-primary/40 space-x-5  flex ">
        {subLinks.map((link, index) => (
          <NavLink
            to={link.path}
            key={index}
            className={({ isActive }) =>
              `${
                isActive ? "text-white " : ""
              } py-4 flex items-center gap-5 font-medium relative`
            }
          >
            <span
              className={`absolute bottom-0 w-full rounded-lg  bg-white duration-300 transition-all shrink-0 ${
                pathname === link.path ? "h-1 opacity-100" : "h-0 opacity-0"
              } `}
            />
            {link.title}
          </NavLink>
        ))}
      </div>
      {/* rendering pages */}
      <div className="w-full h-[calc(100vh-255px)] lg:h-[calc(100vh-210px)] text-black py-3 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default LiveShoppingLayout;
