import React from "react";
import "./style.css";

const CustomLoader = () => {
  return (
    <section className="loader__dots__container bg-white absolute z-[10000] inset-0 ">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </section>
  );
};

export default CustomLoader;
