import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  livedata: null,
  searchLiveText: "",
  searchUserText: "",
};

const liveSlice = createSlice({
  name: "liveInfo",
  initialState,
  reducers: {
    getCurrentLives: (state, action) => {
      state.livedata = action.payload.result.data;
    },
    setSearchText: (state, action) => {
      state.searchLiveText = action.payload;
    },
    setSearchUserText: (state, action) => {
      state.searchUserText = action.payload;
    },
  },
});

export const { getCurrentLives, setSearchText, setSearchUserText } =
  liveSlice.actions;
export const selectSearchText = (state) => state.liveInfo.searchLiveText;
export const selectLiveData = (state) => state.liveInfo.livedata;
export default liveSlice.reducer;
