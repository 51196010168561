export function getFirstTwoLetters(inputString) {
  if (!inputString) return "";
  const words = inputString.split(" ");
  let result = "";
  if (words.length > 1) {
    result = words[0].slice(0, 1) + words[1].slice(0, 1);
  } else result = words[0].slice(0, 2);

  return result;
}
export function filteredElements(data, searchText) {
  const filteredElements = data?.filter((element) =>
    element?.name?.toLowerCase().trim().includes(searchText?.toLowerCase().trim())
  );
  return filteredElements;
}
