// WebSocketService.js
import React, { createContext, useContext, useEffect } from "react";
import Stomp from "stompjs";
import SockJS from "sockjs-client";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const socket = new SockJS(process.env.REACT_APP_SOCKET_URL);
  const stompClient = Stomp.over(socket);

  useEffect(() => {
    stompClient.connect({}, () => {
      console.log("Connexion au  WebSocket");
    });

    return () => {
      if (stompClient.connected) {
        stompClient.disconnect();
        console.log("Déconnexion au WebSocket");
      }
    };
  }, []);

  const value = { stompClient };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = () => useContext(WebSocketContext);

export { WebSocketProvider, useWebSocket };
